import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";
import {received} from "../channels/pm_channel";

// Connects to data-controller="rebate-report"
export default class extends Controller {
  static targets = ["form", "cancelBtn", "downloadBtn", "closeBtn", "updateable", "dateSelect", "message"]
  static outlets = ["flowbite-modal"]

  connect() {
  }

  generateReport(event) {
    if (!event.target.value.length) return

    this.updateAll("cancel")
    this.flowbiteModalOutlet.show()
    this.doGenerateReport()
  }

  async doGenerateReport() {
    let reportId = null

    received((data) => {
      if (data.event === "report_ready" && data.reportId === reportId) {
        this.messageTarget.textContent = "Report is ready. Click the download button to download the file."
        this.updateableTarget.classList.add("hidden")
        this.downloadBtnTarget.href = data.url
        this.downloadBtnTarget.setAttribute("download", data.file_name)
        this.updateAll("download")
      }
    })

    const response = await post(this.formTarget.action, {
      body: new FormData(this.formTarget),
      responseKind: "json"
    })

    // get job id
    reportId = response.report_id
  }

  updateAll(state) {
    console.log("!> updateAll")
    this.updateableTargets.forEach((updateable) => {
      this.update(updateable, state)
    })
    this.update(this.cancelBtnTarget, state)
    this.update(this.downloadBtnTarget, state)
    this.update(this.closeBtnTarget, state)
  }

  update(updateable, state) {
    const toRemove = updateable.dataset[`${state === "cancel" ? "download" : "cancel"}Class`]
    if (toRemove) {
      toRemove.split(" ").forEach((className) => {
        updateable.classList.remove(className)
      })
    }

    const toAdd = updateable.dataset[`${state}Class`]
    if (toAdd) {
      toAdd.split(" ").forEach((className) => {
        updateable.classList.add(className)
      })
    }
  }

  download() {
    console.log("!> download")
    this.downloadBtnTarget.classList.add("hidden")
    this.closeBtnTarget.classList.remove("hidden")
  }

  dismiss() {
    this.dateSelectTarget.value = ""
    this.flowbiteModalOutlet.hide()
  }
}
