import {Controller} from "@hotwired/stimulus"
import {post} from "@rails/request.js"
import _debug from "debug"

const debug = _debug("plaid_controller")

export default class extends Controller {
  static outlets = ["flowbite-modal"]
  static targets = ["button"]
  static values = {
    token: String,
    successUrl: String,
    failureUrl: String,
  }

  connect() {
    debug("Connecting Plaid", this.successUrlValue, this.tokenValue)

    this.handler = Plaid.create({
      token: this.tokenValue,
      onSuccess: (public_token, {account, institution, link_session_id}) => {
        this.dispatch("success")

        // POST the public-token back to the API so it can be exchanged for an access_token
        post(this.successUrlValue, {
          headers: { "Content-Type": "application/json" },
          responseKind: "turbo-stream",
          body: JSON.stringify({plaid_public_token: public_token, institution, account})

        }).then(response => {
          debug("create access_token response okay?", response.ok)
          debug("response:", response)

        }).catch(error => {
          debug("create_access_token_response_error", error)
          this.dispatch("failed", { detail: { error: error } })
          return post(this.failureUrlValue, {
            headers: { "Content-Type": "application/json" },
            responseKind: "turbo-stream",
            body: JSON.stringify(error)
          })

        }).finally(() => {
          if (this.hasButtonTarget) {
            this.buttonTarget.disabled = false
          }
        })
      },
      onLoad: () => {},
      onExit: (err, metadata) => {
        debug("Plaid link error:", err, metadata)
        this.dispatch("exited")
        if (this.hasFlowbiteModalOutlet) {
          this.flowbiteModalOutlet.show();
        }

        if (this.hasButtonTarget) {
          this.buttonTarget.disabled = false
        }
      },
      onEvent: (eventName, metadata) => {},

      //required for OAuth; if not using OAuth, set to null or omit:
      receivedRedirectUri: null,
    })
  }

  open() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = true
    }
    this.handler.open()
  }
}
