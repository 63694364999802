import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';
import {isDesktopBreakpoint} from "../utils/getCurrentBreakpoint";

export default class extends Controller {
  static targets = ['swiper', 'pagination', 'radio', 'submitbtn', 'slide', 'navigation'];
  static values = {
    slidesPerView: { type: Number, default: 1 },
    navigation: { type: String, default: "true" },
  }

  connect() {
    const swiperOptions =
      {
        modules: [Pagination],
        slidesPerView: this.slidesPerViewValue,
        spaceBetween: 8,
        centeredSlides: true,
        direction: 'horizontal',

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      }

    let navigationEnable = true
    if (this.navigationValue === "false") {
      navigationEnable = false
    } else if (this.navigationValue === "desktop") {
      navigationEnable = isDesktopBreakpoint()

      if (!navigationEnable) {
        this.navigationTargets.forEach((nav) => {
          nav.remove()
        })
      }
    }

    if (navigationEnable) {
      swiperOptions.modules.push(Navigation)
      swiperOptions.navigation = {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }

      // This follow is a workaround around an issue with Swiper where
      // the previous button doesn't show disabled after initialization
      setTimeout(() => {
        this.navigationTargets[0].classList.add("swiper-button-disabled")
        this.navigationTargets.forEach((nav) => {
          nav.classList.remove("invisible")
        })
      }, 100)
    }

    this.swiper = new Swiper(this.swiperTarget, swiperOptions);
    this.swiper.on('slideChange', this.handleSwipe.bind(this));
  }

  handleSwipe(_event) {
    this.element.dispatchEvent(new CustomEvent('carousel:change', { bubbles: true, detail: { slide: this.slideTargets[this.swiper.realIndex] } }));
  }
}
