import { Controller } from "@hotwired/stimulus"
import Handlebars from "handlebars"

function addHidden(name, value, parent) {
  const input = document.createElement("input")
  input.setAttribute("type", "hidden")
  input.setAttribute("name", name)
  input.setAttribute("value", value)
  parent.appendChild(input)
}

function setHtml(element, key, templateString, templateData) {
  const template = Handlebars.compile(templateString)
  const html = template(templateData)
  const targetEl = element.querySelector(`[data-key="${key}"]`)
  targetEl.innerHTML = html
  return targetEl
}

// Connects to data-controller="list-container"
export default class extends Controller {
  static outlets = [ "list-filters", "flowbite-modal" ]
  static targets = [ "form", "rowCheckbox", "toolbarActions", "tableForm", "selectAll", "tableBody" ]
  static values = {
    totalCount: Number,
    pageCount: Number,
    resourceNamePlural: String,
    selectAllNoticeClasses: String
  }

  selectAllMatching = false

  get selectAllModal() {
    return this.flowbiteModalOutlets.find((modal) => modal.modal_id === "select-all-modal")
  }

  get confirmActionModal() {
    return this.flowbiteModalOutlets.find((modal) => modal.modal_id === "confirm-action-modal")
  }

  connect() {
  }

  toggleFilters() {
    this.listFiltersOutlet.toggle()
  }

  hideFilters() {
    this.listFiltersOutlet.hide()
  }

  resetAction(event) {
    if (event.detail.success) {
      this.rowCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = false
      })

      this.selectAllTarget.checked = false
      this.clearSelectAllNotice()
      this.toolbarActionsTarget.classList.add("hidden")
    }
  }

  selectAllMatchingCriteria(_event) {
    this.selectAllMatching = true
    this.appendSelectAllNotice()
    this.selectAllModal.hide()
  }

  toggleSelectAll(event) {
    this.rowCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked
    })

    this.displayToolbarActions()

    if (event.target.checked) {
      if (this.totalCountValue > this.pageCountValue) {
        this.selectAllModal.show()
      }
    } else {
      this.clearSelectAllNotice()
    }
  }

  displayToolbarActions() {
    if (!this.hasToolbarActionsTarget) return;

    if (this.rowCheckboxTargets.some((checkbox) => checkbox.checked)) {
      this.toolbarActionsTarget.classList.remove("hidden")
    } else {
      this.toolbarActionsTarget.classList.add("hidden")
    }
  }

  stopPropagation(e) {
    e.stopImmediatePropagation()
  }

  confirmAction(event) {
    this.tableFormTarget.action = event.params.url
    if (!event.params.confirm) {
      this.performAction(event)
      return
    }

    const confirm = event.params.confirm

    let title = confirm.title
    let message = confirm.message
    let confirm_button = confirm.confirm_button
    const count = this.selectAllMatching ? this.totalCountValue : this.rowCheckboxTargets.filter((checkbox) => checkbox.checked).length

    if (title) {
      setHtml(this.confirmActionModal.element, "title", title, { count })
    }

    if (message) {
      setHtml(this.confirmActionModal.element, "message", message, { count })
    }

    const primaryBtn = setHtml(this.confirmActionModal.element, "primaryBtn", confirm_button, {})
    primaryBtn.dataset.listContainerUrlParam = event.params.url

    this.confirmActionModal.show()
  }

  performAction(event) {
    this.confirmActionModal.hide()
    this.tableFormTarget.action = event.params.url
    this.tableFormTarget.requestSubmit()
  }

  updateList(event) {
    let filters = event.detail

    if (!filters) {
      filters = this.listFiltersOutlet.formData
    }

    const combined = new FormData(this.formTarget)

    if (combined.get("search") === "") {
      combined.delete("search")
    }

    // using forEach here breaks system test
    const entries = filters.entries()
    for (const entry of entries) {
      combined.append(entry[0], entry[1])
    }

    const url = new URL(this.formTarget.getAttribute("action"), window.location)
    url.search = new URLSearchParams(combined).toString()

    Turbo.visit(url, { action: this.formTarget.dataset.turboAction || "advance" })
  }

  appendSelectAllNotice() {
    let outer, inner
    if (this.tableBodyTarget.tagName === "TBODY") {
      outer = document.createElement("tr")
      inner = document.createElement("td")
      outer.appendChild(inner)
      inner.setAttribute("colspan", this.tableBodyTarget.rows[0].cells.length)
    } else {
      inner = outer = document.createElement("div")
    }
    outer.setAttribute("id", "select-all-notice")
    this.selectAllNoticeClassesValue.split(" ").forEach((klass) => outer.classList.add(klass))
    inner.innerHTML = `All ${this.totalCountValue} ${this.resourceNamePlural} matching current criteria has been selected`
    this.tableBodyTarget.insertBefore(outer, this.tableBodyTarget.firstChild)

    addHidden("select_all_matching", "1", inner)

    if (this.hasFormTarget) {
      const searchForm = new FormData(this.formTarget)
      if (searchForm.get("search") !== "") {
        addHidden("search", searchForm.get("search"), inner)
      }
    }

    if (this.hasListFiltersOutlet) {
      const filters = this.listFiltersOutlet.formData
      const entries = filters.entries()
      for (const entry of entries) {
        addHidden(entry[0], entry[1], inner)
      }
    }
  }

  clearSelectAllNotice() {
    this.selectAllMatching = false
    this.element.querySelector("#select-all-notice")?.remove()
  }
}
