import { Controller } from "@hotwired/stimulus"
import _debug from "debug"

const debug = _debug("survey-validation-controller")
const validate = require("validate.js")

validate.validators.file = function(value, options, key, attributes) {
  const inputs = document.getElementsByName(key)
  let input = null
  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].type === "file") {
      input = inputs[i]
      break
    }
  }

  if (options.maxFiles && input.files.length > options.maxFiles) {
    return "has too many files selected for upload";
  }

  if (options.maxSize) {
    const files = input.files
    for (let x = 0; x < files.length; x++) {
      const fileSize = ((files[x].size/1024)/1024).toFixed(4); // MB
      if (fileSize > options.maxSize) {
        return "is too large";
      }
    }
  }

  if (options.video) {
    const files = input.files
    for (let x = 0; x < files.length; x++) {
      if (!files[x].type.match(/^video\/.*?$/)) {
        return "is not an acceptable file type";
      }
    }
  }
};

validate.formatters.brief = function(errors) {
  return errors.reduce((memo, error) => {
    memo[error.attribute] = [error.options.message || error.error]
    return memo
  }, {})
};

// Connects to data-controller="survey-validation"
export default class extends Controller {
  static outlets = ["errors", "attachment-field", "flowbite-modal"]

  validated = false

  connect() {
  }

  validate(_event) {
    this.validated = true
    return this.doValidate()
  }

  revalidate() {
    if (this.validated) {
      this.doValidate()
    }
  }

  validateOnSubmit(event) {
    let valid = this.validate()

    if (valid && this.hasAttachmentFieldOutlet) {
      debug("Performing attachment validation", this.attachmentFieldOutlet.numAttachments, this.attachmentFieldOutlet.bypassAttachmentCheck)
      if (this.attachmentFieldOutlet.numAttachments === 0 && !this.attachmentFieldOutlet.bypassAttachmentCheck) {
        this.flowbiteModalOutlet.show()
        valid = false
      }
    }

    if (!valid) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  doValidate() {
    this.errorsOutlet.clearErrors()
    const values = validate.collectFormValues(this.element)
    debug("Survey values:", values)
    const errors = validate(values, this.buildValidationConstraints(), {format: "brief"})

    if (errors) {
      debug("Validation errors:", errors);
      Object.keys(errors).forEach((key) => {
        this.errorsOutlet.showError(key, errors[key][0])
      })

      return false
    }

    return true
  }

  buildValidationConstraints() {
    const constraints = {}
    this.buildRateQuestionConstraints(constraints)
    this.buildTextQuestionConstraints(constraints)
    this.buildVideoQuestionConstraints(constraints)
    this.buildConfirmedTextQuestionConstraints(constraints)
    return constraints
  }

  buildRateQuestionConstraints(constraints) {
    const ratingInputs = this.element.querySelectorAll("input[data-rating-input-target=rating]")
    ratingInputs.forEach((input) => {
      constraints[input.getAttribute('name')] = {
        presence: true,
        numericality: {
          message: "Please leave a rating",
          greaterThan: 0,
          lessThanOrEqualTo: 5
        }
      }
    })
  }

  buildTextQuestionConstraints(constraints) {
    const textInputs = this.element.querySelectorAll("[data-question-type='text'] textarea")
    textInputs.forEach((input) => {
      constraints[input.getAttribute('name')] = {
        presence: {
          message: "Please leave a answer"
        },
      }
    })
  }

  buildConfirmedTextQuestionConstraints(constraints) {
    const yesInputs = this.element.querySelectorAll("[data-question-type='confirmed_text'] input[type='radio'][value='yes']")
    yesInputs.forEach((input) => {
      constraints[input.getAttribute('name')] = {
        presence: {
          message: "Please choose Yes or No"
        },
      }
    })

    const textInputs = this.element.querySelectorAll("[data-question-type='confirmed_text'] textarea")
    textInputs.forEach((input) => {
      constraints[input.getAttribute('name')] = function (value, attributes, attributeName, options, constraints) {
        const yesRadio = input.closest("[data-question-type='confirmed_text']")
          .querySelector("input[type='radio'][value='yes']")

        if (yesRadio.checked) {
          return {
            presence: {
              message: "Please leave a answer"
            }
          }
        }
      }
    })

    const checkboxInputs = this.element.querySelectorAll("[data-question-type='confirmed_text'] input[type='checkbox']")
    checkboxInputs.forEach((input) => {
      constraints[input.getAttribute('name')] = {
        presence: {
          within: ["1"],
          message: "Please check the box to confirm"
        },
      }
    })
  }

  buildVideoQuestionConstraints(constraints) {
    const videoInputs = this.element.querySelectorAll("input[data-video-upload-target=videoField]")
    videoInputs.forEach((input) => {
      const thumbs = input.parentElement.querySelector("div[data-video-upload-target='videoThumbnail']")
      const numUploadedVideos = thumbs.querySelectorAll("div[data-video-uploaded='true']").length

      let validations = {
        file: {
          message: "Up to 3 videos, each less than 500MB in size.",
          video: true,
          maxFiles: 3,
          maxSize: 500
        }
      }
      // if (numUploadedVideos == 0) {
      //   validations["presence"] = {
      //     message: "Please upload a video",
      //   }
      // }

      validations["file"] = {
        message: "Up to 3 videos, each less than 500MB in size.",
        video: true,
        maxFiles: 3 - numUploadedVideos,
        maxSize: 500
      }

      constraints[input.getAttribute('name')] = validations
    })
  }
}
