import { Controller } from "@hotwired/stimulus"
import confetti from "canvas-confetti"

export default class extends Controller {
  static values = { 
    startOnConnect: Boolean 
  }

  initialize() {
    // Define custom shapes once during initialization
    this.confettiShape1 = confetti.shapeFromPath({
      path: 'M1300.99 605.283C1310.09 607.287 1315.19 609.23 1323.19 614.195C1325.68 610.319 1327.13 606.57 1326.89 602.279C1321.85 598.426 1314.71 595.138 1304.73 591.648C1305.7 598.353 1305.11 601.929 1300.99 605.283Z'
    })

    this.confettiShape2 = confetti.shapeFromPath({
      path: 'M399.78 954.193C393.135 953.06 388.829 955.413 382.466 959.167C381.163 966.2 379.419 971.379 379.18 977.337C386.171 980.503 390.125 984.166 394.092 992.972C399.273 978.114 400.031 969.125 399.78 954.193Z'
    })

    this.confettiShape3 = confetti.shapeFromPath({
      path: 'M1019.96 303.534C1020.67 310.238 1024.1 313.745 1029.43 318.854C1036.56 318.204 1042.02 318.48 1047.81 317.097C1048.97 309.51 1051.43 304.712 1058.83 298.509C1043.12 297.544 1034.26 299.249 1019.96 303.534Z'
    })

    this.colors = ['#2ACA34', '#36A88F', '#FFC700', '#FF9D00', '#61BA47']
  }

  connect() {
    if (this.startOnConnectValue) {
      this.start()
    }
  }

  start() {
    const count = 1000
    const defaults = { 
      origin: { y: 1 },
      shapes: [this.confettiShape1, this.confettiShape2, this.confettiShape3],
      colors: ['#2ACA34', '#36A88F', '#FFC700', '#FF9D00', '#61BA47'],
      scalar: 3
    }

    const sequences = [
      { ratio: 0.25, opts: { spread: 90, startVelocity: 100, angle: 135 } },
      { ratio: 0.25, opts: { spread: 180 } },
      { ratio: 0.25, opts: { spread: 90, decay: 0.9, scalar: 2.5, startVelocity: 100, angle: 45} },
      { ratio: 0.25, opts: { spread: 180, startVelocity: 150, scalar: 2.6 } },
    ]

    sequences.forEach(({ ratio, opts }) => {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * ratio)
      })
    })
  }
}